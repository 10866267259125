.material-icons {
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}


h1 {
    font-size: 2.92rem;
    line-height: 110%;
    margin: 1.46rem 0 1.168rem 0
}

h2 {
   	font-size: 2.28rem;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem 0
}

h3 {
    font-size: 1.64rem;
    line-height: 110%;
    margin: 0.82rem 0 0.656rem 0
}

h4 {
    font-size: 1rem;
    line-height: 110%;
    margin: 0.5rem 0 0.4rem 0
}

.dropdown-content {
    min-width: 40px;
}

.dropdown-content li {
    line-height: 1rem;
    text-align: left;
}

.dropdown-content li>a,
.dropdown-content li>span {
	font-size: 1rem;
    line-height: 64px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.language-switch a:hover {
	background-color: darken(#0D47A1, 5%) !important;
}

.language-switch a {
    height: 64px;
	background-color: #0D47A1 !important;
	color: #eceff1 !important;
}

ul.language-mobile {
	text-align: center;
}

ul.language-mobile li {
	display: inline-block;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
}

ul.language-mobile li a {
	padding-left: 2rem;
	padding-right: 2rem;
}

.side-nav li {
	padding-left: 2rem;
	padding-right: 2rem;
}

.side-nav li a {
	background-color: #0D47A1 !important;
	color: #eceff1 !important;
	
}

.side-nav li:hover,
.side-nav li.active {
    background-color: #0D47A1;
}

.side-nav li a:hover,
.side-nav li.active a {
	background-color: darken(#0D47A1, 5%) !important;
}

.en:lang(en),
.de:lang(de),
.it:lang(it) {
    background-color: darken(#0D47A1, 5%) !important;
}

.contact-link {
    line-height: 200%;
}

.contact-link a {
    color: #ffab40;
    -webkit-transition: color .3s ease;
    -moz-transition: color .3s ease;
    -o-transition: color .3s ease;
    -ms-transition: color .3s ease;
    transition: color .3s ease;
}

.contact-link a:hover {
    color: #ffd8a6
}

.contact-box td {
    padding-left: 20px;
    padding-right: 10px;
}

.brand-logo {
	font-size: 1.9rem;
}

.imprint {
    width: 100%;
    height: 56px;
    line-height: 56px
}

.imprint ul {
    margin: 0
}

.imprint ul li {
    -webkit-transition: background-color .3s;
    -moz-transition: background-color .3s;
    -o-transition: background-color .3s;
    -ms-transition: background-color .3s;
    transition: background-color .3s;
    float: left;
    padding: 0
}

.imprint ul li:hover,
.imprint ul li.active {
    background-color: rgba(0, 0, 0, 0.1)
}

.imprint ul a {
    font-size: 1rem;
    color: #fff;
    display: block;
    padding-left: 1em;
    padding-right: 1em;
}

.card-action {
	display: block;
}

.card .card-action a {
	margin-right: 0px;
}

.card-action a {
	float: left;
	margin-bottom: 1.5em;
}

.about-table {

    margin: 0.5rem 0 1rem 0;
}

.about-table-head th {
    border-top: 1px solid #e0e0e0;
    font-size: 2.28rem;
    line-height: 2em;
    margin: 1.14rem 0 0.912rem 0;
    display: table-cell;
    text-align: left;
    font-weight: 400;
    padding: 10px 20px;
}

.about-table-body tr td {
    padding: 10px 20px
}

.home-table li {
    padding: 0 20px;
}

/* Flickity Slider */

/* gallery height */
.gallery {
    padding-bottom: 45%;
}

/* viewport inherit size from gallery */
.gallery .flickity-viewport {
    position: absolute;
    width: 101%;
}

/* cell inherit height from gallery */
.gallery-cell {
    width: 100%;
    height: 100%;
}

.gallery img {
    display: block;
    height: 100%;
}

.welcome-banner {
    color: #000;
    position: absolute;
    height: 0;
    top: 10%;
    right: 10%;
    width: 60%;
    text-align: right;
    z-index: 1;
}

.welcome-banner-small {
    color: #000;
    position: absolute;
    height: 0;
    top: 3%;
    right: 3%;
    width: 40%;
    text-align: right;
    z-index: 1;
}

.welcome-banner-small h3, h4 {
    margin: 0;
}

/* dots are lines */
.flickity-page-dots .dot {
    height: 4px;
    width: 40px;
    margin: 0;
    border-radius: 0;
    background: #0D47A1;
}

/* fade in image when loaded */
.gallery-cell-image {
    transition: opacity 0.6s;
    opacity: 0;
}

.gallery-cell-image.flickity-lazyloaded,
.gallery-cell-image.flickity-lazyerror {
    opacity: 1;
}


/* project gallery height */
.projects-gallery {
  padding-bottom: 50%;
  background: #333;
}

/* viewport inherit size from gallery */
.projects-gallery .flickity-viewport {
  position: absolute;
  width: 100.5%;
}

/* arrow color */
.flickity-prev-next-button .arrow {
  fill: #0D47A1;
}

.projects-gallery img {
  display: block;
  height: 100%;
}

/* more margin e.g. between project sliders */
.more-margin {
    margin-top: 3.5rem;
    margin-bottom: 2.5rem;
}


.collection .collection-item.active {
    background-color: #0D47A1;
    color: #e6ecf5
}

.collection a.collection-item {
    display: block;
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -o-transition: 0.25s;
    -ms-transition: 0.25s;
    transition: 0.25s;
    color: #0D47A1
}

.collection a.collection-item:not(.active):hover {
    background-color: #ddd
}

.collection.with-header .collection-header {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 20px
}

.collection.with-header .collection-item {
    padding-left: 30px
}

.optout {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
}

#amiblu-logo img {
    width: 100%;
    max-width: 200px;
}

#hobas-logo img {
    max-width: 60px
}